import React, { useEffect } from "react";

import { observer } from "mobx-react-lite";
import { useRouter } from "next/router";

import { useDataContext } from "../../../data/DataContext";
import { errorHandlerFn } from "../../../data/notifications/notifications.actions";
import { useI18nLocaleFormat } from "../../../hooks/useI18nLocaleFormat";
import ProposalPanel from "./ProposalPanel";

const ProposalsHomeController = () => {
    // DEPS
    const router = useRouter();
    const { srmService } = useDataContext().services;
    const { notificationsStore, appStateStore, srmStore, authStore } = useDataContext().stores;
    const { localeAs } = useI18nLocaleFormat();

    const { notClosedProposals, closedProposals, proposals } = srmStore;
    const { isSrmUser } = authStore;

    // EFFECTS
    useEffect(() => {
        appStateStore.setIsProgress(true);

        srmService
            .loadProposals()
            .catch(errorHandlerFn(notificationsStore, localeAs, "get", router))
            .finally(finallyHandler);
    }, []);

    // GENERALLY HANDLERS
    const finallyHandler = () => {
        setTimeout(() => appStateStore.setIsProgress(false), 500);
    };

    return (
        <>
            <ProposalPanel activeList proposalList={notClosedProposals} isNotSrmUser={!isSrmUser} />
            {closedProposals?.length > 0 && <ProposalPanel proposalList={closedProposals} className="p-mt-4" />}
        </>
    );
};

export default observer(ProposalsHomeController);
