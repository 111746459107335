import React, { FC } from "react";

import styles from "./ProposalsHome.module.scss";

import Link from "next/dist/client/link";
import { useRouter } from "next/router";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Tag } from "primereact/tag";

import { useDataContext } from "../../../data/DataContext";
import { activityActions } from "../../../data/srm/models/activity/activity.actions";
import { commonActions } from "../../../data/srm/models/common.actions";
import { proposalActions } from "../../../data/srm/models/proposal/proposal.actions";
import { ProposalEntity } from "../../../data/srm/models/proposal/proposal.models";
import { useI18nLocaleFormat } from "../../../hooks/useI18nLocaleFormat";
import APPLICATION_ROUTES from "../../../utils/routes";
import PublicationConsentTag from "../../common/Components/Links/PublicationConsentTag";
import OnlyDevelopment from "../../common/Components/OnlyDevelopment";
import ProposalCardMenu from "./ProposalCardMenu";

interface ProposalCardProps {
    proposal: ProposalEntity;
}

const ProposalCard: FC<ProposalCardProps> = (props) => {
    // PROPS
    const { proposal } = props;

    // DEPS
    const { authStore } = useDataContext().stores;
    const { localeFrom, localeAs } = useI18nLocaleFormat();
    const locale = useRouter().locale;

    // DATA
    const { title, description, severity } = proposalActions.getDescriptionForStatus(proposal, localeAs);
    const isViewButtonView = !proposal?.activity.rights.canPatch;

    const OGImages: string[] = activityActions.extractOGImagesUrls(proposal?.activity, true)[locale];
    const isPartnerScope = activityActions.isPartnerScope(proposal?.activity);
    const isMeetupScope = activityActions.isMeetupScope(proposal?.activity);

    // RENDERS
    const headerRender = () => (
        <div className={styles.card_list__card__header__title}>
            {OGImages.length > 0 ? (
                // eslint-disable-next-line @next/next/no-img-element
                <img
                    alt="Card"
                    src={commonActions.imageActions.getScaledImageUrl(OGImages[0], 600, undefined, "CropUpsize")}
                    style={{ objectFit: "contain" }}
                />
            ) : proposal?.event?.logoUrl ? (
                // eslint-disable-next-line @next/next/no-img-element
                <img
                    alt="Card"
                    src={commonActions.imageActions.getScaledImageUrl(proposal?.event?.logoUrl)}
                    style={{ objectFit: "contain", padding: "1rem" }}
                />
            ) : null}
            {(isPartnerScope || isMeetupScope) && (
                <Tag className={styles.card_list__card__header__title_tag} value={proposal?.activity?.scope} />
            )}
        </div>
    );

    const renderSubtitle = () => {
        if (proposal?.event?.siteUrl) {
            return (
                <a href={localeFrom(proposal?.event?.siteUrl)} target="_blank" rel="noreferrer">
                    {localeFrom(proposal?.event?.name)}
                </a>
            );
        }
        return localeFrom(proposal?.event?.name);
    };

    const footerRender = () => {
        // TODO temporary comment
        // if (proposalActions.isClosedProposal(proposal)) {
        //     return null;
        // }

        const buttonLabel = isViewButtonView
            ? localeAs("page.home.content.proposalCard.viewButton")
            : localeAs("page.home.content.proposalCard.editButton");

        const isMenuRender = proposalActions.isAdditionalActionsAvailable(proposal);

        return (
            <span>
                <Link href={APPLICATION_ROUTES.proposal.proposalRouteMain(proposal.id)}>
                    <Button label={buttonLabel} icon={isViewButtonView ? "pi pi-eye" : "pi pi-pencil"} />
                </Link>
                {isMenuRender && (
                    <OnlyDevelopment>
                        <ProposalCardMenu proposal={proposal} />
                    </OnlyDevelopment>
                )}
            </span>
        );
    };

    return (
        <Card
            title={localeFrom(proposal.activity?.content?.data?.title) ?? "-"}
            subTitle={renderSubtitle()}
            className={styles.card_list__card}
            footer={footerRender}
            header={headerRender}
        >
            {!isPartnerScope && ( // TODO mv to actions || authStore.isAdminCoordinatorEditor(proposal?.event?.id)
                <p className="p-mb-2 p-d-flex" style={{ lineHeight: "1.5" }}>
                    <Tag severity={severity}>{title}</Tag>
                    <PublicationConsentTag
                        className="p-ml-2"
                        memberConsent={proposal.member?.eventProfile?.consent}
                        hideSuccess
                    />
                </p>
            )}
            {!isPartnerScope && ( // TODO mv to actions  || authStore.isAdminCoordinatorEditor(proposal?.event?.id)
                <p className="p-m-0" style={{ lineHeight: "1.5" }}>
                    {description}
                </p>
            )}
        </Card>
    );
};

export default ProposalCard;
